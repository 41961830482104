// SYNC WITH eventive-frontend/app/utils/tracking.js

import Cookies from "js-cookie";

const TRACKING_COOKIE_KEY = "eventive-tracking";

let tracking_data = null;

export function initializeTracking() {
  if (tracking_data) {
    // Only initialize once
    return;
  }

  const params = new URLSearchParams(window.location.search);
  const source = params.get("utm_source");
  const medium = params.get("utm_medium");
  const campaign = params.get("utm_campaign") || params.get("campaign");
  const content = params.get("utm_content");
  const term = params.get("utm_term");

  const existingData = Cookies.getJSON(TRACKING_COOKIE_KEY);

  let data = { ...existingData };
  if (source) {
    data.source = source;
  }
  if (medium) {
    data.medium = medium;
  }
  if (campaign) {
    data.campaign = campaign;
  }
  if (content) {
    data.content = content;
  }
  if (term) {
    data.term = term;
  }

  tracking_data = data;

  let domainParts = window ? window.location.hostname.split(".") : "";
  let baseDomain = (
    domainParts.length >= 3 ? domainParts.slice(1) : domainParts
  ).join(".");
  if (window.location.port) {
    // Debug mode - don't hardcode domain
    baseDomain = undefined;
  }
  Cookies.set(TRACKING_COOKIE_KEY, data, {
    domain: baseDomain,
    expires: 30,
    path: "/"
  });
}

export function generateTrackingPayload() {
  initializeTracking();
  return tracking_data;
}
